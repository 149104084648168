import { Avatar } from "@mui/material";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { Storage } from 'aws-amplify';
import moment from 'moment';
import 'primeicons/primeicons.css';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import EditButton from '../../../../../components/common/buttons/EditButton';
import UserUploadFileButton from '../../../../../components/common/buttons/UploadButton';
import LeftSideBar from '../../../../../components/common/hr/leftnav.component';
import TopNav from '../../../../../components/common/hr/topnav.component';
import { ConvertResponseForSelect } from '../../../../../components/helper/commonfunctions';
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { ValidateHRRole } from '../../../../../components/helper/validateRole';
import { TeamType } from "../../../../../models";
import HRLogin from '../../../../../setup/auth/hr.component';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import popupHeader from '../../../../../assets/images/popup_header_img.png';
import { graphQLGetAllData, graphQLFindRecordById } from '../../../../../components/helper/graphQLFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import { PARAMETER_IDs } from "../../../../../components/helper/enum";
import AWS from 'aws-sdk';
var xlsx = require("xlsx")

export default function HrEmployees() {

    //Configuration 
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
        secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
        region: process.env.REACT_APP_AWS_Region
    });
    var myLambda = new AWS.Lambda();


    let navigateTo = useNavigate();
    const [isPageLoaded, setPageLoaded] = useState(false);
    const [isDeleteUser, setDeleteUser] = useState(false);
    const [openSideBar, setOpenSideBar] = useState(false);
    const [openCalculateActualsPopup, setOpenCalculateActualsPopup] = useState(false);
    const [financialYearList, setFinancialYearList] = useState([]);
    const [selectedFinancialYear, setFinancialYear] = useState("");
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
    const [employeePrimaryKey, setEmployeeePrimaryKey] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [allSBUList, setAllSBUList] = useState([]);
    const [allBIUnit, setAllBIUnit] = useState([]);
    const [allRegions, setAllRegions] = useState([]);
    const [allCountries, setAllCountries] = useState([]);
    const [allBrands, setAllBrands] = useState([]);
    const [allGTM, setAllGTM] = useState([]);
    const [allSubGTM, setAllSubGTM] = useState([]);
    const [allProductGroup, setAllProductGroup] = useState([]);
    const [allCustomerCategory, setAllCustomerCategory] = useState([]);
    const [allCustomer, setAllCustomer] = useState([]);
    const [allProfitCenter, setAllProfitCenter] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [quarter, setQuarter] = useState({ label: "Q1", value: "1" });


    //*Employees Table Columns Name
    const parentDataTableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            cell: (row) => (
                <>
                    <div className="flex gap-4 items-center">
                        <Avatar
                            style={{ width: 28, height: 28 }}
                            alt={row.firstName + "" + row.lastName}
                            src={row.profilePic} />
                        {row.firstName + " " + row.lastName}
                    </div>
                </>
            ),
            width: "200px",
            sortable: true,
        },
        {
            name: "Role",
            selector: row => row.role,
            sortable: true,
        },
        {
            name: "Team Type",
            selector: row => row.teamType,
            sortable: true,
            cell: (row) => (
                row.teamType === TeamType.S ? 'Sales' :
                    row.teamType === TeamType.B ? 'Business' : ''
            ),
        },
        {
            name: "Employee ID",
            selector: row => row.employeeId,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Reporting Manager',
            selector: row => row.parentEmployeeName,
            sortable: true,
            width: "200px",
        },
        {
            name: "Phone No",
            selector: row => row.phoneNo,
            sortable: true,
        },
        {
            name: "Joining Date",
            selector: row => row.joining_date,
            sortable: true,
            width: "200px",
        },
        {
            name: "Confirmation Date",
            selector: row => row.confirmation_date,
            sortable: true,
            width: "200px",
        }, {
            name: "Employee Class",
            selector: row => row.employee_class,
            sortable: true,
            width: "200px",
        }, {
            name: "Employee Type",
            selector: row => row.employee_type,
            sortable: true,
            width: "200px",
        }, {
            name: "Grade",
            selector: row => row.grade,
            sortable: true,
        }, {
            name: "Band",
            selector: row => row.band,
            sortable: true,

        }, {
            name: "Last Working Day",
            selector: row => row.lwd,
            sortable: true,
            width: "200px",
        },
        {
            name: "Assign Target from HR ",
            selector: row => row.isAssignDirectTarget.toString(),
            width: "200px",
            cell: (row) => (
                <>
                    {
                        row.isAssignDirectTarget ?
                            <div className="flex gap-8">
                                <i className='pi pi-check-circle text-[#016939] text-lg' style={{ fontSize: 20 }}></i>
                            </div> : ""
                    }
                </>
            ),
            center: true
        },
        {
            name: 'Action',
            width: "200px",
            cell: (row) => (
                <>
                    <div className="flex gap-8 justify-center">
                        <Link onClick={() => handleCalculateActuals(row)}>
                            <i className="pi pi-calculator mt-1 opacity-80  my-auto relative right-[-14px]" style={{ fontSize: '1.2rem' }}></i></Link>
                        <Link className=" my-auto" onClick={() => handleOpenPopUp(row)}><UserUploadFileButton /></Link>
                        <Link className=" my-auto" onClick={(e) => handleEditClick(e, row['id'])} ><EditButton /></Link>
                        {
                            row.isDisable ?
                                <Link className="disabledCursor opacity-50 my-auto"><DeleteButton /></Link> :
                                <Link className="my-auto" onClick={(e) => handleDeleteClick(e, row['id'])} ><DeleteButton /></Link>
                        }
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            center: true,
            button: true,
        }
    ];

    var quarterList = [
        { label: "Q1", value: "1" },
        { label: "Q2", value: "2" },
        { label: "Q3", value: "3" },
        { label: "Q4", value: "4" },
    ]



    const handleOpenPopUp = (row) => {
        setSelectedEmployeeId(row.id)
        setSelectedEmployee(row.name)
        setOpenSideBar(true)
    }

    const closeCalculatePopup = () => {
        setQuarter('');
        setFinancialYear('');
        setEmployeeePrimaryKey('');
        setSelectedEmployeeId('');
        setOpenCalculateActualsPopup(false);
    }

    const [isActualsCalculated, setActualsCalculated] = useState(false);

    const openAlert = async () => {
        const year = await graphQLFindRecordById('getFinancialYear', selectedFinancialYear);
        const yearFromToDate = moment(year.toDate).format('YYYY');

        if (!selectedFinancialYear) {
            toast.error('Please Select financial Year');
            return;
        }
        if (!quarter) {
            toast.error('Please Select quarter');
            return;
        }

        alertify.confirm('Confirmation', 'Do you want to calculate Actuals?', async function () {

            setActualsCalculated(true);
            const params = {
                FunctionName: 'fn_goalsheet_calculate_employee_actuals',
                Payload: JSON.stringify({
                    "employeeId": employeePrimaryKey,
                    "financialYearId": selectedFinancialYear,
                    "year": yearFromToDate,
                    "quarterNo": quarter
                }),
            };

            const response = await myLambda.invoke(params).promise();

            if (response) {
                toast.success('Actuals are calculated');
            }

            setActualsCalculated(false);
            setOpenCalculateActualsPopup(false);

        }
            , function () {

                setQuarter('');
                setFinancialYear('');
                setEmployeeePrimaryKey('');
                setSelectedEmployeeId('');
                setOpenCalculateActualsPopup(false);
            });
    }

    //Validate admin Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //State
    const [employeeList, SetEmployeeList] = useState([]);
    const [employeeFilterList, SetEmployeeFilterList] = useState([]);

    async function onLoad() {
        try {
            if (!ValidateHRRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //Edit Parent
    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/hr/settings/createemployee/' + id);
    };

    //Delete Employees
    const handleDeleteClick = async (e, id) => {
        e.preventDefault();

        alertify.confirm('Confirmation', 'Do you want to delete this record?', async function () {

            setDeleteUser(true)

            //Delete Employees
            //const objDeleteEmployee = await DataStore.query(Employee, id);
            const objDeleteEmployee = await graphQLFindRecordById('getEmployee', id);
            // await DataStore.delete(objDeleteEmployee);
            await API.graphql({
                query: mutations.deleteEmployee,
                variables: {
                    input: {
                        id: objDeleteEmployee.id,
                        _version: objDeleteEmployee._version,
                    }
                }
            });

            // const objDeleteEmployeeParameters = await DataStore.query(EmployeeParameters, (c) => c.employee_id.eq(id));
            const objDeleteEmployeeParameters = await graphQLGetAllData('listEmployeeParameters', [{ employee_id: { eq: id } }]);


            if (objDeleteEmployeeParameters.length) {
                // await DataStore.delete(objDeleteEmployeeParameters);
                objDeleteEmployeeParameters.forEach(async (o) => {
                    await API.graphql({
                        query: mutations.deleteEmployeeParameters,
                        variables: {
                            input: {
                                id: o.id,
                                _version: o._version,
                            }
                        }
                    });
                })

            }

            //const checkTarget = await DataStore.query(Targets, (c) => c.employee_id.eq(id));
            const checkTarget = await graphQLGetAllData('listTargets', [{ employee_id: { eq: id } }]);

            if (checkTarget.length) {
                for (var i = 0; i < checkTarget.length; i++) {
                    // Delete Target
                    //await DataStore.delete(Targets, checkTarget[i].id)

                    await API.graphql({
                        query: mutations.deleteTargets,
                        variables: {
                            input: {
                                id: checkTarget[i].id,
                                _version: checkTarget[i]._version,
                            }
                        }
                    });

                    // const objDeleteEmployeeTargetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(checkTarget[i].id));

                    const objDeleteEmployeeTargetDetails = await graphQLGetAllData('listTargetDetails', [{ target_id: { eq: checkTarget[i].id } }]);


                    for (var p = 0; p < objDeleteEmployeeTargetDetails.length; p++) {
                        // Delete Target Details
                        // await DataStore.delete(TargetDetails, (c) => c.target_id.eq(checkTarget[i].id))
                        let toDeleteTargetDetails = await graphQLGetAllData('listTargets', [{ target_id: { eq: checkTarget[i].id } }]);

                        toDeleteTargetDetails.forEach(async (o) => {
                            await API.graphql({
                                query: mutations.deleteTargetDetails,
                                variables: {
                                    input: {
                                        id: o.id,
                                        _version: o._version,
                                    }
                                }
                            });
                        })


                        // Delete Plan
                        // await DataStore.delete(Plan, (c) => c.target_detail_id.eq(objDeleteEmployeeTargetDetails[p].id))

                        let toDeletePlans = await graphQLGetAllData('listPlans', [{ target_detail_id: { eq: objDeleteEmployeeTargetDetails[p].id } }]);

                        toDeletePlans.forEach(async (o) => {
                            await API.graphql({
                                query: mutations.deletePlan,
                                variables: {
                                    input: {
                                        id: o.id,
                                        _version: o._version,
                                    }
                                }
                            });
                        })

                    }

                    if (i === checkTarget.length - 1) {
                        setDeleteUser(false)
                        toast.success("Deleted");
                        BindList();
                    }

                }
            } else {
                setDeleteUser(false)
                toast.success("Deleted");
                BindList();
            }
        }
            , function () { });
    };

    const handleCalculateActuals = (row) => {
        setEmployeeePrimaryKey(row.id)
        setSelectedEmployeeId(row.employeeId)
        setSelectedEmployee(row.name)
        setOpenCalculateActualsPopup(true);
    };


    //Get Employees List
    async function BindList() {
        // var financialYearRes = await DataStore.query(FinancialYear, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
        let financialYearRes = await graphQLGetAllData("listFinancialYears")
        financialYearRes = financialYearRes.sort((a, b) => a.name.localeCompare(b.name));
        var res = []; res = ConvertResponseForSelect(financialYearRes); setFinancialYearList(res)

        // var employeeResponse = await DataStore.query(Employee, Predicates.ALL, {
        //     sort: s => s.firstName(SortDirection.ASCENDING)
        // });

        let employeeResponse = await graphQLGetAllData("listEmployees")
        let roles = await graphQLGetAllData("listRoles")
        employeeResponse = employeeResponse.sort((a, b) => a.firstName.localeCompare(b.firstName));

        if (employeeResponse.length > 0) {
            var newResponse = [];
            try {
                employeeResponse.map(async (currentItem, i) => {
                    let profilePic = ""
                    if (currentItem.profilePic !== "") {
                        await Storage.get(currentItem.profilePic).then(result => { profilePic = result });
                    } else {
                        profilePic = "";
                    }

                    let isDisable = false;
                    // var checkForDelete = await DataStore.query(Employee, (c) => c.parentEmployeeId.eq(currentItem.id))
                    var checkForDelete = employeeResponse.find(o => o.parentEmployeeId === currentItem.id);

                    if (checkForDelete) {
                        isDisable = true
                    } else {
                        isDisable = false
                    }

                    //Role List
                    let role = "";
                    let teamType = "";
                    if (currentItem.role_id) {
                        // var roleResponse = await DataStore.query(Roles, currentItem.role_id)
                        var roleResponse = roles.find(o => o.id === currentItem.role_id);

                        if (roleResponse) {
                            role = roleResponse.name
                            teamType = roleResponse.teamType
                        }
                    } else { role = ""; teamType = ""; }


                    //Parent Employee List
                    let empName = "-";
                    let parentEmployeeName = "";
                    let reverseParentEmployeeName = "";
                    if (currentItem.parentEmployeeId !== "") {
                        // var parentResponse = await DataStore.query(Employee, (c) => c.id.eq(currentItem.parentEmployeeId))
                        var parentResponse = employeeResponse.find(o => o.id === currentItem.parentEmployeeId);

                        if (parentResponse && parentResponse.firstName !== null) {
                            parentEmployeeName = parentResponse.firstName + " " + parentResponse.lastName
                            reverseParentEmployeeName = parentResponse.lastName + " " + parentResponse.firstName
                        } else {
                            parentEmployeeName = empName
                        }
                    }
                    newResponse.push({
                        "id": currentItem.id, "role_id": currentItem.role_id,
                        "name": currentItem.firstName + " " + currentItem.lastName,
                        "reverseName": currentItem.lastName + " " + currentItem.firstName,
                        "firstName": currentItem.firstName, "lastName": currentItem.lastName,
                        "email": currentItem.email, "parentEmployeeId": currentItem.parentEmployeeId, "address1": currentItem.address1, "address2": currentItem.address2, "phoneNo": currentItem.phoneNo,
                        "city": currentItem.city, "zip": currentItem.zip, "state": currentItem.state, "country": currentItem.country, "employeeId": currentItem.employeeId, "profilePic": profilePic, "isAssignDirectTarget": currentItem.isAssignDirectTarget, "param_sbu_id": currentItem.param_sbu_id,
                        "IsHRAccess": currentItem.IsHRAccess, "IsFinanceAccess": currentItem.IsFinanceAccess, "isDisable": isDisable, "role": role, "teamType": teamType,
                        "parentEmployeeName": parentEmployeeName, "reverseParentEmployeeName": reverseParentEmployeeName, "joining_date": currentItem.joining_date === "1970-05-07" ? "-" : currentItem.joining_date, "confirmation_date": currentItem.confirmation_date === "1970-05-07" ? "-" : currentItem.confirmation_date,
                        "employee_class": currentItem.employee_class, "employee_type": currentItem.employee_type, "grade": currentItem.grade, "band": currentItem.band, "lwd": currentItem.lwd === "1970-05-07" ? "-" : currentItem.lwd,
                    });

                    // //On Last Record assign to state
                    if (i === employeeResponse.length - 1) {
                        let sortArray = newResponse.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
                        SetEmployeeList(sortArray);
                        SetEmployeeFilterList(sortArray);
                        setPageLoaded(true);
                    }
                })
            } catch (e) {

            }
        } else {
            SetEmployeeList([]);
            setPageLoaded(true);
        }
    }

    const getMasterData = async () => {
        let sbuArray = await graphQLGetAllData("listSBUS")
        setAllSBUList(sbuArray)

        let BIUnitArray = await graphQLGetAllData("listBIUnits")
        setAllBIUnit(BIUnitArray)

        let regionsArray = await graphQLGetAllData("listRegions")
        setAllRegions(regionsArray)

        let countryArray = await graphQLGetAllData("listCountries")
        setAllCountries(countryArray)

        let brandArray = await graphQLGetAllData("listBrands")
        setAllBrands(brandArray)

        let GTMArray = await graphQLGetAllData("listGTMS")
        setAllGTM(GTMArray)

        let subGTMArray = await graphQLGetAllData("listSubGTMS")
        setAllSubGTM(subGTMArray)

        let productGroupArray = await graphQLGetAllData("listProductGroups")
        setAllProductGroup(productGroupArray)

        let customerCategoryArray = await graphQLGetAllData("listCustomerCategories")
        setAllCustomerCategory(customerCategoryArray)

        let customerArray = await graphQLGetAllData("listCustomers")
        setAllCustomer(customerArray)

        let parameterResponses = await graphQLGetAllData("listParameters")
        SetParametersList(parameterResponses)

        let profitCenterArray = await graphQLGetAllData("listProfitCenters")
        setAllProfitCenter(profitCenterArray)
    }


    useEffect(() => {
        // bindData()
        onLoad();
        BindList();
        getMasterData();
    }, []);

    //Search Employee
    const searchEmployees = (searchTerm) => {
        if (searchTerm.length > 1 && searchTerm != null) {
            let data = [...employeeFilterList]

            const filteredArr = data.filter((obj) => {
                return Object.values(obj).some((value) => {
                    return typeof value === "string" && value.trim().toLowerCase().replace(/\s+/g, "").includes(searchTerm.trim().toLowerCase().replace(/\s+/g, ""));
                });
            });

            SetEmployeeList(filteredArr)
        } else {
            let data = [...employeeFilterList]
            SetEmployeeList(data)
        }
    }


    const [parameterList, SetParametersList] = useState([]);

    //// ------------------------------------------------------------ MASTER DATA  **** START ***** --------------------------------------------------------------


    //*get SBUId________
    const getSBUId = (name) => {
        let _sbuList = [...allSBUList];
        var returnRecord = _sbuList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get BuId________
    const getBUId = (name) => {
        let _buList = [...allBIUnit];
        var returnRecord = _buList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get Bu REF_CODE________
    const getBuREF_CODE = (name) => {
        let _buList = [...allBIUnit];
        var returnRecord = _buList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.REF_CODE; } else { return ""; }
    }

    //*get RegionId________
    const getRegionId = (sbuId, regionId) => {
        let _regionList = [...allRegions];
        if (regionId) {
            var returnRecord = _regionList.find(o => (o.SBU_id.toLowerCase().trim() === sbuId.toLowerCase().trim() && o.name.toLowerCase().trim() === regionId.toLowerCase().trim()));
            if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
        }
    }

    //*get CountryId________
    const getCountryId = (regionId, name) => {
        let _countryList = [...allCountries];
        var returnRecord = _countryList.find(o => o.region_id?.toLowerCase().trim() === regionId?.toLowerCase().trim() && o.name?.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //*get CountryId________
    const getBrandId = (sbuId, buId, brandName) => {
        let _brandList = [...allBrands];
        var returnRecord = _brandList.find(o => (o.SBU_id.toLowerCase().trim() === sbuId.toLowerCase().trim() && o.bu_id.toLowerCase().trim() === buId.toLowerCase().trim() && o.name.toLowerCase().trim() === brandName.toLowerCase().trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Product Group________
    const getProductGroupId = (name) => {
        if (!name) return;
        let _productGroupList = [...allProductGroup];
        var returnRecord = _productGroupList.find(o => o.name.toLowerCase().trim() === name?.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get Financial Year_______
    const getParameterId = (name) => {
        let _parameterList = [...parameterList];
        var returnRecord = _parameterList.find(o => o.name.toLowerCase().trim() === name?.toLowerCase().trim());
        // var returnRecord = _parameterList.find(o => o.name.toLowerCase().trim().includes(name.toLowerCase().trim()));
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get GtmId
    const getGtmId = (name) => {
        let _gtmList = [...allGTM];
        var returnRecord = _gtmList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //get GtmId
    const getSubGtmId = (name) => {
        let _subGtmList = [...allSubGTM];
        var returnRecord = _subGtmList.find(o => o.name.toLowerCase().trim() === name.toLowerCase().trim());
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    const getCustomerGroupId = (cgname, gtmId) => {
        let returnRecord = allCustomerCategory.find(c => c.name === cgname && c.gtm_i === gtmId);
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    const getCustomerId = (refCode) => {
        let returnRecord = allCustomer.find(c => c.REF_CODE === refCode);
        if (returnRecord !== null && returnRecord !== undefined) { return returnRecord.id; } else { return ""; }
    }

    //// ------------------------------------------------------------ MASTER DATA  **** END ***** --------------------------------------------------------------


    const changeAttachment = (e) => { setSelectedFile(e) }

    const importFile = () => {
        if (selectedFinancialYear === "") { toast.error("Select financial year"); return }
        if (selectedFile.length === 0) { toast.error("Select file to upload"); return }
        confirmDialog({
            message: 'Do you want to import this file?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => readUploadFile(selectedFile)
        });

    }

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            setShowLoader(true)
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                let EmpName = worksheet['B3']?.v;
                let EmpId = worksheet['B4']?.v;
                let DOJ = worksheet['B5']?.v;
                let EmpDesign = worksheet['B6']?.v;
                let EmpReportingManager = worksheet['B11']?.v;
                let FinancialYearValue = worksheet['B14']?.v;
                let empData = { "EmpName": EmpName, "EmpId": EmpId, "DOJ": DOJ, "EmpDesignation": EmpDesign, "ReportingManager": EmpReportingManager, "FinancialYearValue": FinancialYearValue }
                const sheetGoal = workbook.SheetNames[1];
                const worksheetGoal = workbook.Sheets[sheetGoal];
                const range = xlsx.utils.decode_range(worksheetGoal['!ref']);
                const endRow = range.e.r;
                const endColumn = range.e.c;
                const importRangeGoal = `A${1}:${xlsx.utils.encode_col(endColumn)}${endRow + 1}`;

                let goalSheetHeaders = ["SBU", "BU", "Cluster", "Country", "GTM", "SUBGTM", "Brand", "Product Group", "Customer Group", "Customer", "ProfitCenter"]
                const employeeParameters = xlsx.utils.sheet_to_json(worksheetGoal, { range: importRangeGoal, header: goalSheetHeaders });
                const importRange = "A16:G30";
                const headers = ["parameter", "weightage", "q1_target", "q2_target", "q3_target", "q4_target", "year_target"];
                const json = xlsx.utils.sheet_to_json(worksheet, { range: importRange, header: headers });
                if (json) {
                    json.forEach((obj, index) => {
                        obj["key"] = "Parameter " + (index + 1);
                        const worksheet = workbook.Sheets["Parameter " + (index + 1)];
                        const getParameterData = xlsx.utils.sheet_to_json(worksheet);
                        if (getParameterData.length) {
                            const range = xlsx.utils.decode_range(worksheet['!ref']);
                            const endRow = range.e.r;
                            const endColumn = range.e.c;

                            const importRange = `A${6}:${xlsx.utils.encode_col(endColumn)}${endRow + 1}`;
                            const headers = ["SBU", "BU", "Cluster", "Country", "GTM", "SubGTM", "Brand", "Product Group", "Customer Group", "Customer", "Q1", "Q2", "Q3", "Q4", "AllTarget"];
                            const json = xlsx.utils.sheet_to_json(worksheet, { range: importRange, header: headers });
                            obj["parameterData"] = json.length ? json : []
                        }
                    });
                    saveData(json, empData, employeeParameters)
                }
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const saveData = async (empTargetData, empPersonalData, employeeParameters) => {
        if (empPersonalData) {
            //Save employee Parameters
            if (employeeParameters.length) {

                let allData = await graphQLGetAllData("listEmployeeParameters", [{ employee_id: { eq: selectedEmployeeId } }])

                for (let i = 0; i < allData.length; i++) {
                    await API.graphql({
                        query: mutations.deleteEmployeeParameters,
                        variables: {
                            input: {
                                id: allData[i].id,
                                _version: allData[i]._version
                            }
                        }
                    });
                }

                // OLD DataStore Code
                // await DataStore.delete(EmployeeParameters, (c) => c.employee_id.eq(selectedEmployeeId))

                let filterEmployeeParameters = employeeParameters.filter((e) => e.SBU && e.BU)

                if (filterEmployeeParameters.length) {
                    for (let i = 0; i < filterEmployeeParameters.length; i++) {
                        let currentSBU = filterEmployeeParameters[i].SBU ? allSBUList.find(o => o.name.toUpperCase() === filterEmployeeParameters[i].SBU.toUpperCase()) : '';
                        if (currentSBU) {
                            //BU REF CODE
                            // let bUResponse = await DataStore.query(BIUnit, (c) => c.name.eq(filterEmployeeParameters[i].BU));
                            let bUResponse = filterEmployeeParameters[i].BU ? allBIUnit.find(o => o.name.toUpperCase() === filterEmployeeParameters[i].BU.toUpperCase()) : '';
                            let bU_REF_CODE = bUResponse ? bUResponse.REF_CODE : "";
                            let buId = bUResponse ? bUResponse.id : "";

                            //Cluster/Region CODE
                            // let ClusterResponse = await DataStore.query(Region, (c) => c.name.eq(filterEmployeeParameters[i].Cluster.toUpperCase()));
                            let ClusterResponse = [];
                            if (filterEmployeeParameters[i].Cluster) {
                                ClusterResponse = filterEmployeeParameters[i].Cluster ? allRegions.find(o => o.name.toUpperCase() === filterEmployeeParameters[i].Cluster.toUpperCase()) : '';
                            }
                            let Cluster_REF_CODE = ClusterResponse ? ClusterResponse.REF_CODE : "";
                            let clusterId = ClusterResponse ? ClusterResponse.id : "";

                            //Country CODE
                            // let countryResponse = await DataStore.query(Country, (c) => c.name.eq(filterEmployeeParameters[i].Country.toUpperCase()));
                            let countryResponse = filterEmployeeParameters[i].Country ? allCountries.find(o => o.name.toUpperCase() === filterEmployeeParameters[i].Country.toUpperCase()) : '';
                            let country_REF_CODE = countryResponse ? countryResponse.REF_CODE : "";
                            let countryId = countryResponse ? countryResponse.id : "";

                            //Brand CODE
                            // let brandResponse = await DataStore.query(Brand, (c) => c.name.eq(filterEmployeeParameters[i].Brand));
                            let brandResponse = filterEmployeeParameters[i].Brand ? allBrands.find(o => o.name.toUpperCase() === filterEmployeeParameters[i].Brand.toUpperCase()) : '';
                            let brand_REF_CODE = brandResponse ? brandResponse.REF_CODE : "";


                            //GTM CODE
                            // let gtmResponse = await DataStore.query(GTM, (c) => c.name.eq(filterEmployeeParameters[i].GTM));
                            let gtmResponse = filterEmployeeParameters[i].GTM ? allGTM.find(o => o.name.toUpperCase() === filterEmployeeParameters[i].GTM.toUpperCase()) : '';
                            let GTM_REF_CODE = gtmResponse ? gtmResponse.REF_CODE : "";
                            let GTMId = gtmResponse ? gtmResponse.id : "";

                            //SubGtm CODE
                            // let subGtmResponse = await DataStore.query(SubGTM, (c) => c.name.eq(filterEmployeeParameters[i].SUBGTM));
                            let subGtmResponse = filterEmployeeParameters[i].SUBGTM ? allSubGTM.find(o => o.name.toUpperCase() === filterEmployeeParameters[i].SUBGTM.toUpperCase()) : '';
                            let SubGtm_REF_CODE = subGtmResponse ? subGtmResponse.REF_CODE : "";
                            let SubGtmId = subGtmResponse ? subGtmResponse.id : "";

                            //PG
                            // let pgResponse = await DataStore.query(ProductGroup, (c) => c.name.eq(filterEmployeeParameters[i]['Product Group']));
                            let pgResponse = filterEmployeeParameters[i]['Product Group'] ? allProductGroup.find(o => o.name.toUpperCase() === filterEmployeeParameters[i]['Product Group'].toUpperCase()) : '';
                            let PG_REF_CODE = pgResponse ? pgResponse.REF_CODE : "";

                            // //CusGroup
                            // let customerGroupResponse = await DataStore.query(CustomerCategory, (c) => c.name.eq(filterEmployeeParameters[i].CustomerGroup));
                            // let customerGroupResponse = allCustomerCategory.find(o => o.name === filterEmployeeParameters[i]['Customer Group'] ? filterEmployeeParameters[i]['Customer Group'] : filterEmployeeParameters[i]['Customer Group']);
                            let customerGroupResponse = filterEmployeeParameters[i]['Customer Group'] ? allCustomerCategory.find(o => o.name.toUpperCase() === filterEmployeeParameters[i]['Customer Group'].toUpperCase()) : '';

                            let CG_REF_CODE = customerGroupResponse ? customerGroupResponse.REF_CODE : "";
                            let CgId = customerGroupResponse ? customerGroupResponse.id : "";
                            //Customer
                            // let customerResponse = await DataStore.query(Customer, (c) => c.name.eq(filterEmployeeParameters[i].Customer));
                            // Found using name but we are getting customer code - Pratap
                            let customerResponse = allCustomer.find(o => o.REF_CODE == filterEmployeeParameters[i].Customer);
                            let Customer_REF_CODE = customerResponse ? customerResponse.REF_CODE : "";
                            let CustomerId = customerResponse ? customerResponse.id : "";

                            //ProfitCenter
                            // let profitCenterResponse = await DataStore.query(ProfitCenter, (c) => c.name.contains(filterEmployeeParameters[i].ProfitCenter));
                            let profitCenterResponse = allProfitCenter.find(o => o.name == filterEmployeeParameters[i].ProfitCenter);
                            let profitCenter_REF_CODE = profitCenterResponse ? profitCenterResponse.REF_CODE : "";
                            let profitCenterId = profitCenterResponse ? profitCenterResponse.id : "";

                            //Save Record Now.
                            // await DataStore.save(
                            //     new EmployeeParameters({
                            //         "employee_id": selectedEmployeeId,
                            //         "bu_id": buId,
                            //         "bu_REF_CODE": bU_REF_CODE,
                            //         "region_id": clusterId,
                            //         "region_REF_CODE": Cluster_REF_CODE,
                            //         "country_id": countryId,
                            //         "country_REF_CODE": country_REF_CODE,
                            //         "brand_REF_CODE": brand_REF_CODE,
                            //         "gtm_id": GTMId,
                            //         "gtm_REF_CODE": GTM_REF_CODE,
                            //         "sub_gtm_id": SubGtmId,
                            //         "sub_gtm_REF_CODE": SubGtm_REF_CODE,
                            //         "product_group_REF_CODE": PG_REF_CODE,
                            //         "customer_group_id": CgId,
                            //         "customer_segment_REF_CODE": CG_REF_CODE,
                            //         "customer_id": CustomerId,
                            //         "customer_REF_CODE": Customer_REF_CODE,
                            //         "profile_center_id": profitCenterId,
                            //         "profit_center_REF_CODE": profitCenter_REF_CODE
                            //     })
                            // );

                            await API.graphql(
                                graphqlOperation(mutations.createEmployeeParameters, {
                                    input: {
                                        employee_id: selectedEmployeeId,
                                        bu_id: buId,
                                        bu_REF_CODE: bU_REF_CODE,
                                        region_id: clusterId,
                                        region_REF_CODE: Cluster_REF_CODE,
                                        country_id: countryId,
                                        country_REF_CODE: country_REF_CODE,
                                        brand_REF_CODE: brand_REF_CODE,
                                        gtm_id: GTMId,
                                        gtm_REF_CODE: GTM_REF_CODE,
                                        sub_gtm_id: SubGtmId,
                                        sub_gtm_REF_CODE: SubGtm_REF_CODE,
                                        product_group_REF_CODE: PG_REF_CODE,
                                        customer_group_id: CgId,
                                        customer_segment_REF_CODE: CG_REF_CODE,
                                        customer_id: CustomerId,
                                        customer_REF_CODE: Customer_REF_CODE,
                                        profile_center_id: profitCenterId,
                                        profit_center_REF_CODE: profitCenter_REF_CODE
                                    }
                                })
                            );

                        }
                    }
                }
            }

            //Check User
            // let checkFinancialYear = await DataStore.query(FinancialYear, (c) => c.id.eq(selectedFinancialYear))
            let checkFinancialYear = await graphQLFindRecordById("getFinancialYear", selectedFinancialYear);

            // let empDetails = await DataStore.query(Employee, (c) => c.id.eq(selectedEmployeeId))
            let empDetails = await graphQLFindRecordById("getEmployee", selectedEmployeeId);

            if (empDetails && checkFinancialYear) {
                //Check weightage Relationship
                // var checkWeightageRelation = await DataStore.query(Relationship, (c) => c.and(c => [
                //     c.role_id.eq(empDetails.role_id),
                //     c.financial_year_id.eq(checkFinancialYear.id)
                // ]));

                let checkWeightageRelation = await graphQLGetAllData("listRelationships", [{ role_id: { eq: empDetails.role_id }, financial_year_id: { eq: checkFinancialYear.id } }])

                if (checkWeightageRelation.length === 0) {
                    // await DataStore.save(
                    //     new Relationship({
                    //         "financial_year_id": checkFinancialYear.id,
                    //         "role_id": empDetails.role_id,
                    //     })
                    // ).then(async (response) => { await saveWeightage(response.id, empTargetData) })

                    let createRelationship = await API.graphql(
                        graphqlOperation(mutations.createRelationship, {
                            input: {
                                financial_year_id: checkFinancialYear.id,
                                role_id: empDetails.role_id,
                            }
                        })
                    );
                    await saveWeightage(createRelationship.data.createRelationship.id, empTargetData)
                }

                // let checkTarget = await DataStore.query(Targets, (c) => c.and(c => [
                //     c.employee_id.eq(empDetails.id),
                //     c.report_manager_employee_id.eq(empDetails.id),
                //     c.financial_year_id.eq(checkFinancialYear.id)
                // ]));

                // Reporting manager id is removed for deleting all records of employee
                let checkTarget = await graphQLGetAllData("listTargets", [{ employee_id: { eq: empDetails.id }, financial_year_id: { eq: checkFinancialYear.id } }])

                if (checkTarget.length) {
                    for (var n = 0; n < checkTarget.length; n++) {
                        // Delete Target
                        // await DataStore.delete(Targets, checkTarget[i].id)

                        await API.graphql({
                            query: mutations.deleteTargets,
                            variables: {
                                input: {
                                    id: checkTarget[n].id,
                                    _version: checkTarget[n]._version
                                }
                            }
                        });

                        // const objDeleteEmployeeTargetDetails = await DataStore.query(TargetDetails, (c) => c.target_id.eq(checkTarget[i].id));

                        const objDeleteEmployeeTargetDetails = await graphQLGetAllData("listTargetDetails", [{ target_id: { eq: checkTarget[n].id } }])

                        for (var p = 0; p < objDeleteEmployeeTargetDetails.length; p++) {
                            // Delete Target Details
                            // await DataStore.delete(TargetDetails, (c) => c.target_id.eq(checkTarget[i].id))

                            await API.graphql({
                                query: mutations.deleteTargetDetails,
                                variables: {
                                    input: {
                                        id: objDeleteEmployeeTargetDetails[p].id,
                                        _version: objDeleteEmployeeTargetDetails[p]._version
                                    }
                                }
                            });

                            // Delete Plan
                            // await DataStore.delete(Plan, (c) => c.target_detail_id.eq(objDeleteEmployeeTargetDetails[p].id))
                            const objDeletePlan = await graphQLGetAllData("listPlans", [{ target_detail_id: { eq: objDeleteEmployeeTargetDetails[p].id } }])

                            for (var k = 0; k < objDeletePlan.length; k++) {
                                await API.graphql({
                                    query: mutations.deletePlan,
                                    variables: {
                                        input: {
                                            id: objDeletePlan[k].id,
                                            _version: objDeletePlan[k]._version
                                        }
                                    }
                                });
                            }
                        }
                    }
                }

                let targetDetails = await API.graphql(
                    graphqlOperation(mutations.createTargets, {
                        input: {
                            financial_year_id: checkFinancialYear.id,
                            employee_id: empDetails.id,
                            role_id: empDetails.role_id,
                            report_manager_employee_id: empDetails.parentEmployeeId,
                            isAddedByHRAdmin: false,
                            isAssignedToEmployee: true,
                            isSubmittedByEmployee: true
                        }
                    })
                );

                saveTargetDetails(targetDetails.data.createTargets.id, empTargetData)

            }
        }
    }

    const saveWeightage = async (relationId, empTargetData) => {
        // let checkRelationExist = await DataStore.query(RelationshipDetails, (c) => c.relationship_id.eq(relationId))
        let checkRelationExist = await graphQLGetAllData("listRelationshipDetails", [{ relationship_id: { eq: relationId } }])

        try {
            if (checkRelationExist.length === 0) {
                for (var i = 0; i < empTargetData.length; i++) {
                    if (empTargetData[i].parameterData.length > 0) {
                        let parameterId = getParameterId(empTargetData[i].parameter.trim())

                        // await DataStore.save(
                        //     new RelationshipDetails({
                        //         "relationship_id": relationId,
                        //         "parameter_id": parameterId,
                        //         "weightage": parseFloat(empTargetData[i].weightage) * 100
                        //     })
                        // ).then((response) => { })

                        await API.graphql(
                            graphqlOperation(mutations.createRelationshipDetails, {
                                input: {
                                    "relationship_id": relationId,
                                    "parameter_id": parameterId,
                                    "weightage": parseFloat(empTargetData[i].weightage) * 100
                                }
                            })
                        );
                    }

                }
            }

        } catch (e) { }
    }

    const saveCurrentTargetDetails = async (parameterData, targetId) => {
        let parameterId = getParameterId(parameterData.parameter.trim())
        if (parameterId) {
            let createdTargetDetails = await API.graphql(
                graphqlOperation(mutations.createTargetDetails, {
                    input: {
                        target_id: targetId,
                        parameter_id: parameterId,
                        target_value: parameterData.year_target ? String(parameterData.year_target) : "0",
                        q1_target_value: parameterData.q1_target ? String(parameterData.q1_target) : "0",
                        q2_target_value: parameterData.q2_target ? String(parameterData.q2_target) : "0",
                        q3_target_value: parameterData.q3_target ? String(parameterData.q3_target) : "0",
                        q4_target_value: parameterData.q4_target ? String(parameterData.q4_target) : "0",
                        target_assigned_date: moment().format(),
                        is_accepted_by_employee: true,
                        is_approve: true,
                        approved_date: moment().format(),
                        accepted_by_employee_date: moment().format(),
                        isMarkAsComplete: true,
                        isMarkAsComplete_AssignTarget: true,
                    }
                })
            );
            savePlan(createdTargetDetails.data.createTargetDetails.id, parameterData.parameterData)
        }
    }

    const saveNotIncludedTargetDetails = async (parameterId, targetId) => {
        let notFoundParameterDetails = parameterList.find(o => o.id === parameterId)
        let createdTargetDetails = await API.graphql(
            graphqlOperation(mutations.createTargetDetails, {
                input: {
                    target_id: targetId,
                    parameter_id: parameterId,
                    target_value: "0",
                    q1_target_value: "0",
                    q2_target_value: "0",
                    q3_target_value: "0",
                    q4_target_value: "0",
                    target_assigned_date: moment().format(),
                    is_accepted_by_employee: true,
                    is_approve: true,
                    approved_date: moment().format(),
                    accepted_by_employee_date: moment().format(),
                    isMarkAsComplete: true,
                    isMarkAsComplete_AssignTarget: true,
                }
            })
        );
        let myParamArray = [];
        myParamArray.push(notFoundParameterDetails)
        savePlan(createdTargetDetails.data.createTargetDetails.id, myParamArray)
    }



    const saveTargetDetails = async (targetId, getEmpTargetData) => {
        
        let empTargetData = getEmpTargetData.filter((a) => a.parameterData)


        let NWCParameterDetails = empTargetData.find(o => o.parameter === "NWC Days")
        let NWCV2ParameterDetails = empTargetData.find(o => o.parameter === "NWC Days V2")
        let DIOParameterDetails = empTargetData.find(o => o.parameter === "DIO")
        let DSOParameterDetails = empTargetData.find(o => o.parameter === "DSO")
        let DVROParameterDetails = empTargetData.find(o => o.parameter === "DVRO")
        let DPOParameterDetails = empTargetData.find(o => o.parameter === "DPO")
        let GMPParameterDetails = empTargetData.find(o => o.parameter === "GM %")
        let GMDParameterDetails = empTargetData.find(o => o.parameter === "GM $")
        let RevenueParameterDetails = empTargetData.find(o => o.parameter === "Revenue")

        if (GMPParameterDetails || GMDParameterDetails) {
            GMDParameterDetails ? saveCurrentTargetDetails(GMDParameterDetails, targetId) : saveNotIncludedTargetDetails(PARAMETER_IDs['Gross Margin $'], targetId);
            GMPParameterDetails ? saveCurrentTargetDetails(GMPParameterDetails, targetId) : saveNotIncludedTargetDetails(PARAMETER_IDs['Gross Margin %'], targetId);
        }

        if (RevenueParameterDetails) {
            saveCurrentTargetDetails(RevenueParameterDetails, targetId)
        }

        if (NWCV2ParameterDetails) {
            saveCurrentTargetDetails(NWCV2ParameterDetails, targetId)
        }

        if (NWCParameterDetails) {
            saveCurrentTargetDetails(NWCParameterDetails, targetId)
            DIOParameterDetails ? saveCurrentTargetDetails(DIOParameterDetails, targetId) : saveNotIncludedTargetDetails(PARAMETER_IDs.DIO, targetId);
            DSOParameterDetails ? saveCurrentTargetDetails(DSOParameterDetails, targetId) : saveNotIncludedTargetDetails(PARAMETER_IDs.DSO, targetId);
            DVROParameterDetails ? saveCurrentTargetDetails(DVROParameterDetails, targetId) : saveNotIncludedTargetDetails(PARAMETER_IDs.DVRO, targetId);
            DPOParameterDetails ? saveCurrentTargetDetails(DPOParameterDetails, targetId) : saveNotIncludedTargetDetails(PARAMETER_IDs.DPO, targetId);
        }
        else {
            if (DIOParameterDetails)
                saveCurrentTargetDetails(DIOParameterDetails, targetId)

            if (DSOParameterDetails)
                saveCurrentTargetDetails(DSOParameterDetails, targetId)

            if (DVROParameterDetails)
                saveCurrentTargetDetails(DVROParameterDetails, targetId)

            if (DPOParameterDetails)
                saveCurrentTargetDetails(DPOParameterDetails, targetId)
        }

        // Remove previously added parameters from array
        empTargetData = empTargetData.filter((e) =>
            e.parameter !== "GM %"
            && e.parameter !== "GM $"
            && e.parameter !== "Revenue"
            && e.parameter !== "NWC Days"
            && e.parameter !== "DIO"
            && e.parameter !== "DSO"
            && e.parameter !== "DVRO"
            && e.parameter !== "DPO"
            && e.parameter !== "NWC Days V2"
        )


        for (let k = 0; k < empTargetData.length; k++) {
            saveCurrentTargetDetails(empTargetData[k], targetId)
        }

        setShowLoader(false);
        setOpenSideBar(false)
        setFinancialYear("");
        setSelectedEmployee("")
        toast.success("Success.");

        // try {
        //     for (var i = 0; i < empTargetData.length; i++) {
        //         if (i === empTargetData.length - 1) {
        //             setShowLoader(false);
        //             setOpenSideBar(false)
        //             setFinancialYear("");
        //             setSelectedEmployee("")
        //             toast.success("Success.");
        //         }

        //         if (empTargetData[i].parameterData.length > 0) {
        //             let parameterId = getParameterId(empTargetData[i].parameter.trim())

        //             // await DataStore.save(
        //             //     new TargetDetails({
        //             //         "target_id": targetId,
        //             //         "parameter_id": parameterId,
        //             //         "target_value": empTargetData[i].year_target ? String(empTargetData[i].year_target) : "0",
        //             //         "q1_target_value": empTargetData[i].q1_target ? String(empTargetData[i].q1_target) : "0",
        //             //         "q2_target_value": empTargetData[i].q2_target ? String(empTargetData[i].q2_target) : "0",
        //             //         "q3_target_value": empTargetData[i].q3_target ? String(empTargetData[i].q3_target) : "0",
        //             //         "q4_target_value": empTargetData[i].q4_target ? String(empTargetData[i].q4_target) : "0",
        //             //         "target_assigned_date": moment().format(),
        //             //         "is_accepted_by_employee": true,
        //             //         "is_approve": true,
        //             //         "approved_date": moment().format(),
        //             //         "accepted_by_employee_date": moment().format(),
        //             //         "isMarkAsComplete": true,
        //             //         "isMarkAsComplete_AssignTarget": true,
        //             //     })
        //             // ).then((result) => {
        //             //     savePlan(result.id, empTargetData[i].parameterData)
        //             // })



        //             let createdTargetDetails = await API.graphql(
        //                 graphqlOperation(mutations.createTargetDetails, {
        //                     input: {
        //                         target_id: targetId,
        //                         parameter_id: parameterId,
        //                         target_value: empTargetData[i].year_target ? String(empTargetData[i].year_target) : "0",
        //                         q1_target_value: empTargetData[i].q1_target ? String(empTargetData[i].q1_target) : "0",
        //                         q2_target_value: empTargetData[i].q2_target ? String(empTargetData[i].q2_target) : "0",
        //                         q3_target_value: empTargetData[i].q3_target ? String(empTargetData[i].q3_target) : "0",
        //                         q4_target_value: empTargetData[i].q4_target ? String(empTargetData[i].q4_target) : "0",
        //                         target_assigned_date: moment().format(),
        //                         is_accepted_by_employee: true,
        //                         is_approve: true,
        //                         approved_date: moment().format(),
        //                         accepted_by_employee_date: moment().format(),
        //                         isMarkAsComplete: true,
        //                         isMarkAsComplete_AssignTarget: true,
        //                     }
        //                 })
        //             );
        //             savePlan(createdTargetDetails.data.createTargetDetails.id, empTargetData[i].parameterData)
        //         }
        //     }
        // } catch (e) { }
    }

    var savePlan = async (targetDetailId, findData) => {


        for (var i = 0; i < findData.length; i++) {
            let sbuId; if (findData[i].SBU) { sbuId = getSBUId(findData[i].SBU) } else { sbuId = "" }
            if (sbuId) {
                let buId; let buREF_CODE;
                if (findData[i].BU) {
                    buId = getBUId(findData[i].BU)
                    buREF_CODE = getBuREF_CODE(findData[i].BU)
                } else { buId = ""; buREF_CODE = ""; }

                let regionId;
                if (findData[i].Cluster) { regionId = getRegionId(sbuId, findData[i].Cluster) } else { regionId = "" }

                let countryId;
                if (regionId) { countryId = getCountryId(regionId, findData[i].Country) } else { countryId = "" }

                let brandId;
                if (findData[i].Brand) { brandId = getBrandId(sbuId, buId, findData[i].Brand) } else { brandId = "" }

                let productGroupId;
                if (findData[i]["Product Group"]) { productGroupId = getProductGroupId(findData[i]["Product Group"]) } else { productGroupId = "" }
                let gtmId;
                if (findData[i].GTM) { gtmId = getGtmId(findData[i].GTM) } else { gtmId = "" }

                let subGtmId;
                if (findData[i].SubGTM) { subGtmId = getSubGtmId(findData[i].SubGTM) } else { subGtmId = "" }

                let customerGroupId;
                if (findData[i]["Customer Group"]) { customerGroupId = getCustomerGroupId(findData[i]["Customer Group"], gtmId) }

                let customerId;
                if (findData[i]["Customer"]) { customerId = getCustomerId(findData[i]["Customer"]) }

                // await DataStore.save(
                //     new Plan({
                //         "target_detail_id": targetDetailId,
                //         "bu_REF_CODE": buREF_CODE,
                //         "sbu_id": sbuId,
                //         "bu_id": buId,
                //         "region_id": regionId,
                //         "country_id": countryId,
                //         "brand_id": brandId,
                //         "product_group_id": productGroupId,
                //         "customer_group_id": "0",
                //         "customer_id": "0",
                //         "gtm_Id": gtmId,
                //         "sub_gtm_id": subGtmId,
                //         "target_value": findData[i].annualTarget ? String(findData[i].annualTarget) : "0",
                //         "q1_target_value": findData[i].Q1 ? String(findData[i].Q1) : "0",
                //         "q2_target_value": findData[i].Q2 ? String(findData[i].Q2) : "0",
                //         "q3_target_value": findData[i].Q3 ? String(findData[i].Q3) : "0",
                //         "q4_target_value": findData[i].Q4 ? String(findData[i].Q4) : "0",
                //     })
                // )

                await API.graphql(
                    graphqlOperation(mutations.createPlan, {
                        input: {
                            target_detail_id: targetDetailId,
                            bu_REF_CODE: buREF_CODE,
                            sbu_id: sbuId,
                            bu_id: buId,
                            region_id: regionId,
                            country_id: countryId,
                            brand_id: brandId,
                            product_group_id: productGroupId,
                            customer_group_id: customerGroupId,
                            customer_id: customerId,
                            gtm_Id: gtmId,
                            sub_gtm_id: subGtmId,
                            target_value: findData[i].annualTarget ? String(findData[i].annualTarget) : "0",
                            q1_target_value: findData[i].Q1 ? String(findData[i].Q1) : "0",
                            q2_target_value: findData[i].Q2 ? String(findData[i].Q2) : "0",
                            q3_target_value: findData[i].Q3 ? String(findData[i].Q3) : "0",
                            q4_target_value: findData[i].Q4 ? String(findData[i].Q4) : "0",
                        }
                    })
                );

            }
        }
    }

    const handleSelectedRowsChange = (selectedRows) => {
        setSelectedEmployees(selectedRows.selectedRows);
    };

    const handelSendEmail = async () => {

        for (let i = 0; i < selectedEmployees.length; i++) {
            const params = {
                FunctionName: 'RedingtonEvalMailFunction',
                Payload: JSON.stringify({
                    "to": selectedEmployees[i].email
                }),
            };

            const response = await myLambda.invoke(params).promise();
            if (response.StatusCode === 200) {
                toast.success('Email Sent!');
            }

        }

        setSelectedEmployees([]);
    }

    // ------------------------------------------------------------------------------------------------------


    return isAuthenticated ? (
        <div>
            <div className="pt-16">
                <TopNav />
                <LeftSideBar />
                <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                    <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                        <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                            <div className="md:flex block md:col-span-2 mb-4">
                            </div>
                            <div className="md:flex block mb-4">
                                <h2 className="page-title">Employees</h2>
                            </div>
                        </div>

                        <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                            <div className="relative">
                                <input onChange={(e) => searchEmployees(e.target.value)} type="text" id="required-search" className="rounded-md border border-[#D0D5DD] focus:border-transparent w-full py-2.5 pl-3 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#403E3C] dark:text-[#FFFFFF] dark:border-[#403E3C]" placeholder="Search..." />
                            </div>
                            <div className="md:flex block  gap-4 mb-4">
                                <Link to="/hr/settings" className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">Back</Link>
                                <Link to="/hr/settings/createemployee" className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Create</Link>
                                <Link to="/hr/settings/organizationchart" className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Organization Chart</Link>
                                {selectedEmployees.length > 0 && <Link to="#" className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] "
                                    onClick={() => {
                                        handelSendEmail();
                                    }}
                                >Send E-mail</Link>}
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className={"block w-full pt-5"}>
                            {
                                isPageLoaded &&
                                <DataTable
                                    columns={parentDataTableColumns}
                                    data={employeeList}
                                    customStyles={DataTableCustomStyles}
                                    pagination
                                    highlightOnHover
                                    paginationPerPage={10}
                                    selectableRows // Enable row selection
                                    selectableRowsHighlight // Highlight the selected rows
                                    onSelectedRowsChange={handleSelectedRowsChange}
                                />
                            }

                            {
                                !isPageLoaded &&
                                <div className='text-center py-8' ><ProgressSpinner /></div>
                            }


                            {
                                <Dialog visible={isDeleteUser} style={{ width: '40vw' }} showHeader={false}>
                                    <div className='text-center py-8' ><ProgressSpinner /></div>
                                    <h2 className="page-title text-center">Please wait while deleting employee.</h2>
                                </Dialog>
                            }
                        </div>
                    </div>
                </div>

                <>
                    {
                        openSideBar &&
                        <div style={{ width: "34.271vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="editPopupCont" className="sidenav">

                            <div className="popup_header relative">
                                <img src={popupHeader} alt="" />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Employee Details</div>
                                        <div><Link onClick={() => setOpenSideBar(false)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                    </div>
                                    <div className="flex justify-between px-6 m mt-6 lg:mt-8 2xl:mt-5 xl:mt-3">
                                        <div>
                                            <div className="text-sm">Employee Name: {selectedEmployee}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-6 height-custtom">

                                <Dropdown disabled={showLoader ? true : false} value={selectedFinancialYear} onChange={(e) => setFinancialYear(e.value)} options={financialYearList}
                                    optionLabel="label"
                                    placeholder="Select Financial Year" className="w-full md:w-14rem" />

                                <div className='mt-10'>
                                    {
                                        selectedFinancialYear &&
                                        <>
                                            <span>Browse File</span>
                                            <div>
                                                <button className="border text-[Black] flex bg-[white] rounded-md p-2  border-[#D0D5DD] pr-3 cursor-pointer">
                                                    <input
                                                        // onChange={(e)=> readUploadFile}
                                                        onChange={(e) => { changeAttachment(e) }}
                                                        accept=".xlsx" id="fileImport"
                                                        multiple="multiple" type="file" className="cursor-pointer" />
                                                </button>
                                            </div>
                                            <span className='text-muted font-size-muted'>Note: Please select .xlsx file</span>
                                        </>
                                    }
                                </div>

                                {
                                    showLoader &&
                                    <div style={{ textAlign: "center" }}>
                                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                    </div>
                                }

                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-5">
                                {
                                    <div className="flex justify-end gap-2">
                                        <div><Link onClick={() => setOpenSideBar(false)} className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                        <div><Link onClick={() => importFile()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Import</Link></div>
                                    </div>
                                }
                            </div>


                        </div>
                    }
                </>
                <ConfirmDialog />
                <>
                    {
                        openCalculateActualsPopup &&
                        <div style={{ width: "34.271vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="editPopupCont" className="sidenav">

                            <div className="popup_header relative">
                                <img src={popupHeader} alt="" />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md">Calculate Actuals</div>
                                        <div onClick={() => closeCalculatePopup()} ><Link className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                    </div>
                                    <div className="flex justify-between px-6 m mt-6 lg:mt-8 2xl:mt-5 xl:mt-3">
                                        <div>
                                            <div className="text-sm">Employee ID: {selectedEmployeeId}</div>
                                        </div>
                                        <div>
                                            <div className="text-sm">Employee Name: {selectedEmployee}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isActualsCalculated === false ?
                                <div className="p-6 height-custtom">
                                    <div>
                                        <div className="text-sm">Employee Name: <b>{selectedEmployee}</b></div>
                                    </div>
                                    <div>
                                        <label className="text-sm flex items-center dark:text-white mt-4">Financial Year <label className="text-[#FF0000] text-[14px] font-medium">
                                            * </label>
                                        </label>
                                    </div>

                                    <Dropdown disabled={showLoader ? true : false} value={selectedFinancialYear} onChange={(e) => setFinancialYear(e.value)} options={financialYearList}
                                        optionLabel="label"
                                        placeholder="Select Financial Year" className=" mt-4 w-full md:w-14rem" />

                                    <div>
                                        <label className="text-sm flex items-center  dark:text-white mt-4">Quarter <label className="text-[#FF0000] text-[14px] font-medium">
                                            * </label>
                                        </label>
                                    </div>

                                    <Dropdown disabled={showLoader ? true : false} value={quarter} onChange={(e) => setQuarter(e.value)} options={quarterList}
                                        optionLabel="label"
                                        placeholder="Select Quarter" className=" mt-4 w-full md:w-14rem" />

                                    {
                                        showLoader &&
                                        <div style={{ textAlign: "center" }}>
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                        </div>
                                    }

                                </div>
                                :
                                <div className='text-center py-8' ><ProgressSpinner /></div>

                            }


                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] dark:bg-[#071008] p-5">
                                {
                                    <div className="flex justify-end gap-2">
                                        <div onClick={() => closeCalculatePopup()} ><Link className="bg-white border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white" >Cancel</Link></div>
                                        <div><Link onClick={() => openAlert()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">{isActualsCalculated === true ? 'Please Wait' : 'Calculate Actuals'}</Link></div>
                                    </div>
                                }
                            </div>


                        </div>
                    }
                </>
            </div>
        </div>
    ) : (
        <HRLogin sessionExpired={1} />
    )
}
